import React from "react"
import {Link} from 'gatsby';
import Layout from "../../components/bluescope/layout"
import seologo from "../../images/bluescope/appicon256.png"
import Seo from "../../components/SEO"

import * as styles from '../../css/actionfaq.module.css'

function BlueScopeFAQPage(props) {

    const seoImage = {
		src: seologo,
		height: 256,
		width: 256,
		alt: "BlueScope for iOS app logo",
    };

    return (
    <Layout>
        <Seo
            siteTitle={'BlueScope® FAQ'}
            seoTitle='BlueScope FAQ'
            path={props.location.pathname}
            metaImage={seoImage}
            icon={seologo}
            description='BlueScope® for iOS - FAQ'
            keywords={["BlueScope", "BlueScope FAQ", "BlueScope How to", "BlueScope support"]}
            datePublished="12-10-2020"
            dateModified="05-08-2023"
            is_app={true}
            appName={"BlueScope"}
            appId={"1530096924"}
        />
        
        <div className={"container"}>
            <div className={"content"}>
                <div className={"title"}>
                    <h1 style={{textDecoration: 'None', color: '#0398F4'}}>BlueScope® for iOS</h1>
                    <h2>Frequently Asked Questions</h2>
                </div>

                {/* <div className={styles.faqSection}> */}
                <h2 className={styles.header}>Subscriptions</h2>
                <div className={styles.faqContent}>
                    <ol>
                        <li><h3 className={styles.title}>Can I use the subscription on more than one device?</h3></li>
                        <p className={styles.desc}>
                            Yes, you can use purchased and active subscriptions on another device by using the same Apple Id that was originally used for subscription purchase. Then you can restore the purchases from within App Preferences.
                        </p>

                        <li><h3 className={styles.title}>Why is Restore Purchases not working?</h3></li>
                        <p className={styles.desc}>
                            Make sure you have an active Internet connection. Secondly, Restore will only work if (a) there was actually a purchase made via a valid Apple Id, and (b) you're using the same Apple Id while attempting to Restore. Delete the app from your device and download again by logging in to the correct Apple Id.
                        </p>

                        <li><h3 className={styles.title}>How can I upgrade from monthly to yearly subscription?</h3></li>
                        <p className={styles.desc}>
                            You can switch between monthly and yearly subscriptions at any time. Simply go to App Preferences > Premium tab, then select the subscription tier you want to change to.
                        </p>

                        <li><h3 className={styles.title}>How can I cancel the subscription if I'm not satisfied?</h3></li>
                        <p className={styles.desc}>
                            We hope you like BlueScope® and would continue to use it. If something is not working as expected, please let us know and we'll try to address it. We would love to hear your feedback. You can send us an email from within App Preferences > Support, or you can <Link to="/contact" style={{textDecoration: 'None', color: '#0398F4'}}>contact us</Link> via the web form. You can, however, cancel the subscription at any time. Please contact Apple Support for any cancellations.
                        </p>

                    </ol>
                </div>
                
                <h2 className={styles.header}>Using BlueScope®</h2>
                <div className={styles.faqContent}>
                    <ol>
                        <li><h3 className={styles.title}>How can I add or remove devices to my list?</h3></li>
                        <p className={styles.desc}>
                            Use long tap on a device to open a context menu and select 'Add to my list', or 'Remove from my list'. You can also tap on the device and open the single device view and tap on the 'Heart' button to Add or Remove the device from the list.
                        </p>

                        <li><h3 className={styles.title}>Why don't I see all of my Bluetooth® devices?</h3></li>
                        <p className={styles.desc}>
                            BlueScope® exclusively supports BLE (Bluetooth® Low Energy) devices. Almost all of the smart devices are BLE devices. Secondly, if the devices are powered off or out of range, they won't be detected and not shown in the list.
                        </p>

                        <li><h3 className={styles.title}>How do I identify a device?</h3></li>
                        <p className={styles.desc}>
                            Device Identification is a premium feature and needs an active premium subscription. Open App Preferences by tapping on the gear icon on the top right. Then tap on 'Show Device Information' to enable Device Information.
                        </p>

                        <li><h3 className={styles.title}>How can I see the battery information for my device?</h3></li>
                        <p className={styles.desc}>
                        Device Identification is a premium feature and needs an active premium subscription. Open App Preferences by tapping on the gear icon on the top right. Then tap on 'Show Extended Device Information' to enable Extended Device Information. <span style={{textDecoration: 'None', color: '#0398F4'}}>Note: Battery Information is only shown for devices that support it.</span>
                        </p>

                    </ol>
                </div>

                <h2 className={styles.header}>Lost and Found Notifications</h2>
                <div className={styles.faqContent}>
                    <h5 className={styles.desc}>
                        <span style={{textDecoration: 'None', color: '#0398F4'}}>Lost and Found notifications is a premium feature and needs an active premium subscription.</span>
                    </h5>

                    <ol>
                    <li><h3 className={styles.title}>Can I set Lost & Found notification for any device?</h3></li>
                        <p className={styles.desc}>
                            Though BlueScope® can detect almost all devices within the range, it is recommended that you only set notifications for the devices that you own yourself. BlueScope® cannot, and is not meant to track any devices. This is also set forth in the <Link to="/bluescope/terms" style={{textDecoration: 'None', color: '#0398F4'}}>Terms of Use</Link>. If you set notifications for devices that you do not own yourself, it may not work as expected.
                        </p>

                    <li><h3 className={styles.title}>How do I set Lost and Found notifications?</h3></li>
                        <p className={styles.desc}>
                            If you have an active premium subscription, you can enable notifications in two ways. 1) Use long tap on the device to bring up the context menu and select "Notify when Found" or "Notify when Lost" as appropriate. 2) Alternatively, you can open the single device view by tapping on the device and then select the notify button on the bottom to enable notifications.
                        </p>

                        <li><h3 className={styles.title}>How do I enable Lost and Found notifications when the app is not running?</h3></li>
                        <p className={styles.desc}>
                            If you have an active premium subscription, you can enable background notifications from with App Preferences > Notifications > Enable background notifications.
                        </p>

                        <li><h3 className={styles.title}>How can I cancel an active notification?</h3></li>
                        <p className={styles.desc}>
                            If you have an active premium subscription, you can cancel an active notification in two ways. 1) Use long tap on the device to bring up the context menu and select "Cancel Notification". 2) Alternatively, you can open the single device view by tapping on the device and then select the Cancel Notify button on the bottom.
                        </p>

                        <li><h3 className={styles.title}>Why my notification is not working?</h3></li>
                        <p className={styles.desc}>
                            If you restart your device or force quit the app, BlueScope® cannot monitor the Bluetooth® devices and may not be able to notify you. When you restart your device, open BlueScope® and verify that all your notifications are in place.
                        </p>

                    </ol>
                </div>

                <h2 className={styles.header}>Map and Directions</h2>
                <div className={styles.faqContent}>
                    <h5 className={styles.desc}>
                        <span style={{textDecoration: 'None', color: '#0398F4'}}>Map and Directions is a premium feature and needs an active premium subscription.</span>
                    </h5>

                    <ol>
                        <li><h3 className={styles.title}>How can I view the map?</h3></li>
                        <p className={styles.desc}>
                            Tap on a device in the device list to open the device view. Then select the Map button in the middle of the screen to see the map. To view the map, an active premium subscription is required and Location services are needed to be enabled.
                        </p>

                        <li><h3 className={styles.title}>How can I see the last location of a lost Bluetooth® device?</h3></li>
                        <p className={styles.desc}>
                            Tap on a device in the device list to open the device view. Then select the Map button in the middle of the screen to see the map. If the location services were enabled at the time when the device was detected earlier, its location was automatically saved. When you open the map, it automatically shows the last location if available.
                        </p>

                        <li><h3 className={styles.title}>How to get the directions to a lost Bluetooth® device?</h3></li>
                        <p className={styles.desc}>
                            In the Map view, when it shows the last location of a lost device, tap on the device icon to bring up the menu and then select the directions button on the right. Currently, Apple Maps is supported for directions.
                        </p>


                    </ol>

                </div>

                    <h3 className={styles.title}>I have some other question which is not answered here.</h3>
                    <p className={styles.desc}>
                        If you have additional questions, please <Link to="/contact" style={{textDecoration: 'None', color: '#0398F4'}}>contact us</Link>. You can also send us an email from within the app by going to App Preferences > Support Email.
                    </p>
            </div>
            {/* </div> */}
        </div>

        <div className={"container"}>
            <div className={"notes"}>
                <ul>
                    <li>BlueScope® is a registered trademark of Malaud LLC.</li>
                    <li>Bluetooth® is a registered trademark of Bluetooth SIG.</li>
                    <li>
                        Apple®, iOS®, iPhone®, iPad®, App Store®, Apple Watch®, Apple Maps®, AirPods®, Siri® are registered trademarks of Apple Inc.
                    </li>
                </ul>      
            </div>
        </div>

    </Layout>
    )
}

export default BlueScopeFAQPage
